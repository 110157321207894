import React from 'react'
import saf from '../Assets/saf5.jpg'
import laptop from '../Assets/laptop.webp'
import docs from '../Assets/docs.webp'

export default function GetStarted() {
  return (
  <div >
    <div className='info-pic-container' style={{borderBottom: '1px solid #363636'}}>
      <div className='horizontal-info'>
          <div className='beige-header'>
              <h2>book a tailored audit</h2>
          </div>

          <p className='top-margin' style={{ maxWidth: 650}}>If you're seeking an external partner to energise and motivate your existing team and strategies, our marketing audit is the perfect starting point. </p>

          <p style={{marginTop: 50}}>Here's what our audit offers:</p>

          <ul style={{paddingLeft: 50, marginTop: 30}}>
            <li>A fresh perspective on your current marketing efforts</li>
            <li style={{margin: '10px 0'}}>Identification of untapped opportunities and potential roadblocks</li>
            <li>Actionable recommendations tailored to your unique business needs</li>
          </ul>

          <p style={{marginTop: 50, maxWidth: 650}}>By booking a marketing audit with us, you'll gain clarity on your marketing landscape and receive expert guidance on how to optimise your approach by partnering with S.</p>

      </div>

      <img src={saf} className='info-img'/>
    </div>

    <div className='info-pic-container' style={{borderBottom: '1px solid #363636'}}>
      <div className='horizontal-info'>
          <div className='beige-header'>
              <h2>Need to know more?</h2>
          </div>

          <p className='top-margin' style={{ maxWidth: 550}}>We understand that choosing the right partner isn’t an easy decision. To help you make an informed choice, we invite you to download our creds deck. Inside, you'll find detailed information about our services, success stories, and the unique value we bring to our partners. </p>
          <a 
              href='https://firebasestorage.googleapis.com/v0/b/advidity-facb4.appspot.com/o/s-theagency%2FS.%20Growth%20Partners.pdf?alt=media&token=ee5104f5-aeda-4100-9d4d-07bb5a9f068b'
              target='_blank'
          >
          <button className='black-btn' style={{width: 200, marginTop: 70}}>Download now</button>
          </a>
      </div>

      <img src={laptop} className='info-img'/>
    </div>

    <div className='info-pic-container' >
      <div className='horizontal-info'>
          <div className='beige-header'>
              <h2>Know what you want?</h2>
          </div>

          <p className='top-margin' style={{ maxWidth: 550}}>If you have a clear vision of what you're looking for, we invite you to schedule a meeting with us. Together, we can strategise and develop a tailored plan that aligns with your goals and drives success. </p>
          <a href='https://calendly.com/google-s-theagency/30min' target='_blank'>
            <button className='black-btn' style={{width: 200, marginTop: 70}}>Book a meeting</button>
          </a>
      </div>

      <img src={docs} className='info-img'/>
    </div>
  </div>
  )
}
